






























































































































































































































































































































































































































































































































































































































































































































































































































































.searchMain {

	.ivu-input,
	::v-deep .ivu-select-selection,
	::v-deep .ivu-select-selected-value,
	::v-deep .ivu-select-placeholder {
		height: 0.5rem !important;
		line-height: 0.5rem !important;

		@media (max-width: 767px) {
			height: 0.5rem * 1.5 !important;
			line-height: 0.5rem * 1.5 !important;
		}
	}

	::v-deep .ivu-input {
		height: 0.5rem !important;

		@media (max-width: 767px) {
			height: 0.5rem * 1.5 !important;
		}
	}

	.ivu-form-item {
		@media (max-width: 767px) {
			margin-bottom: 0.3rem;
		}
	}

	::v-deep .ivu-input-icon {
		    line-height: 0.5rem;
		    font-size: 0.14rem;

		@media (max-width: 767px) {
			line-height: 32px;
			font-size: 16px;
		}
	}
}

